<template>
    <div id="dialog-update-alert-component">
        <v-dialog v-model="is_dialog_edit_alert_open" max-width="500px">
            <v-card>
                <v-card-title>
                    <p class="text-uppercase font-weight-bold mb-1 line-height-24">
                        {{ $t('dialog_update_alert_component.configure_alert') }}
                    </p>
                </v-card-title>

                <v-card-text class="pb-0">
                    <!-- Rebuild v-for to fix bug -->
                    <v-form v-if="!rebuild" ref="form_edit_alert">
                        <div v-for="(configuration, index) in local_alert.configuration" :key="index">
                            <p v-if="index > 0" class="text-uppercase font-weight-bold my-4 text-center font-16">
                                {{ $t('dialog_update_alert_component.and') }}
                            </p>

                            <v-card>
                                <v-card-title class="text-uppercase justify-space-between pointer py-1">
                                    <span
                                        class="font-14 font-weight-bold w-80"
                                        @click="manageDisplayAlertConfigurationSection('configuration_' + index)"
                                    >
                                        {{ $t('dialog_update_alert_component.rule') }}
                                        {{ local_alert.configuration.length > 1 ? index + 1 : '' }}
                                    </span>
                                    <v-icon v-if="local_alert.configuration.length > 1" @click="deleteConditionToAlert(index)">
                                        mdi-close
                                    </v-icon>
                                </v-card-title>

                                <v-card-text v-if="display_alert_configuration_section['configuration_' + index]">
                                    <v-select
                                        v-model="metric_of_configurations[index]"
                                        :item-text="(item) => `${item.label} ${item.unit ? `(${item.unit})` : ''}`"
                                        :items="metrics"
                                        :label="$t('dialog_update_alert_component.measure_to_monitor')"
                                        :loading="fetching_metrics"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        class="mb-4"
                                        filled
                                        hide-details
                                        item-value="slug"
                                        return-object
                                    >
                                        <template v-slot:item="{ item, on, attrs }">
                                            <v-list-item v-bind="attrs" v-on="on">
                                                <div>
                                                    <p class="font-color-normal mb-n2">
                                                        {{ `${item.label} ${item.unit ? `(${item.unit})` : ''}` }}
                                                    </p>
                                                    <span class="font-10 font-color-light">
                                                        {{
                                                            item.is_principal
                                                                ? $t('dialog_update_alert_component.principal')
                                                                : $t('dialog_update_alert_component.secondary')
                                                        }}
                                                    </span>
                                                </div>
                                            </v-list-item>
                                        </template>
                                    </v-select>

                                    <v-select
                                        v-model="local_alert.configuration[index].type"
                                        :items="alert_calculators"
                                        :label="$t('dialog_update_alert_component.type')"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        filled
                                        item-text="text"
                                        item-value="value"
                                    />

                                    <v-expand-transition>
                                        <template v-if="metric_of_configurations[index]">
                                            <div v-if="local_alert.configuration[index].type === 'limit'">
                                                <p class="mb-0 font-weight-bold">
                                                    {{ $t('dialog_update_alert_component.define_alert_condition') }}
                                                </p>

                                                <v-radio-group v-model="local_alert.configuration[index].operator" class="mt-1">
                                                    <v-radio value=">">
                                                        <template v-slot:label>
                                                            <span class="font-14">
                                                                {{ $t('dialog_update_alert_component.superior') }}
                                                            </span>
                                                        </template>
                                                    </v-radio>

                                                    <v-radio value="<">
                                                        <template v-slot:label>
                                                            <span class="font-14">
                                                                {{ $t('dialog_update_alert_component.inferior') }}
                                                            </span>
                                                        </template>
                                                    </v-radio>

                                                    <v-radio value="=">
                                                        <template v-slot:label>
                                                            <span class="font-14">
                                                                {{ $t('dialog_update_alert_component.equal') }}
                                                            </span>
                                                        </template>
                                                    </v-radio>
                                                </v-radio-group>

                                                <v-text-field
                                                    v-model="local_alert.configuration[index].limit"
                                                    :label="$t('dialog_update_alert_component.value')"
                                                    filled
                                                    type="number"
                                                />
                                            </div>

                                            <div v-else-if="local_alert.configuration[index].type === 'variation'">
                                                <p class="mb-0 font-weight-bold">
                                                    {{ $t('dialog_update_alert_component.define_alert_condition') }}
                                                </p>

                                                <v-radio-group v-model="local_alert.configuration[index].operator" class="mt-1">
                                                    <v-radio value="-">
                                                        <template v-slot:label>
                                                            <span class="font-14">
                                                                {{ $t('dialog_update_alert_component.diminution') }}
                                                            </span>
                                                        </template>
                                                    </v-radio>

                                                    <v-radio value="+">
                                                        <template v-slot:label>
                                                            <span class="font-14">
                                                                {{ $t('dialog_update_alert_component.augmentation') }}
                                                            </span>
                                                        </template>
                                                    </v-radio>
                                                </v-radio-group>

                                                <v-text-field
                                                    v-model="local_alert.configuration[index].limit"
                                                    :label="$t('dialog_update_alert_component.value')"
                                                    filled
                                                    hide-details
                                                    type="number"
                                                />

                                                <p class="my-2">
                                                    {{ $t('dialog_update_alert_component.on') }}
                                                </p>

                                                <v-row class="ma-0">
                                                    <v-text-field
                                                        v-model="local_alert.configuration[index].variation_time"
                                                        :label="$t('dialog_update_alert_component.value')"
                                                        class="text-field-duo"
                                                        filled
                                                        type="number"
                                                    />
                                                    <v-spacer />
                                                    <v-select
                                                        v-model="local_alert.configuration[index].time_unites_selected"
                                                        :items="time_unites"
                                                        :label="$t('dialog_update_alert_component.unity')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        class="text-field-duo"
                                                        filled
                                                        item-text="label"
                                                        item-value="value"
                                                        return-object
                                                    />
                                                </v-row>
                                            </div>
                                        </template>
                                    </v-expand-transition>
                                </v-card-text>
                            </v-card>
                        </div>

                        <v-btn class="my-4" color="secondary" outlined @click="addConditionToAlert()">
                            <v-icon color="secondary">mdi-plus</v-icon>
                            <span class="font-weight-bold secondary--text">
                                {{ $t('dialog_update_alert_component.add_condition') }}
                            </span>
                        </v-btn>

                        <v-expand-transition>
                            <div v-if="local_alert.configuration[0].type !== null && metric_of_configurations[0]">
                                <v-divider class="my-4" />

                                <!--------------------------
                                    ALERT DISPLAY OPTION
                                --------------------------->
                                <div>
                                    <v-card>
                                        <v-card-title
                                            class="pointer py-1"
                                            @click="manageDisplayAlertConfigurationSection('option_display')"
                                        >
                                            <p class="font-weight-bold mb-2 font-14 text-uppercase">
                                                {{ $t('dialog_update_alert_component.alert_appearance') }}
                                            </p>
                                        </v-card-title>

                                        <v-expand-transition>
                                            <v-card-text v-if="display_alert_configuration_section['option_display']">
                                                <v-text-field
                                                    v-model="local_alert.label"
                                                    :label="$t('dialog_update_alert_component.name_alert')"
                                                    class="mb-6"
                                                    filled
                                                    hide-details
                                                    type="text"
                                                />

                                                <icon-picker-component
                                                    :color_icon="local_alert.configuration_appearance.icon_untriggered"
                                                    :icon="local_alert.configuration_appearance.icon_untriggered"
                                                    :label="$t('dialog_update_alert_component.icon_alert_not_trigger')"
                                                    type="unactive"
                                                    v-on:update:icon="handleUpdateIcon"
                                                />

                                                <v-sheet
                                                    :color="local_alert.configuration_appearance.color_untriggered"
                                                    class="mb-4 mt-2 rounded rounded-b-0"
                                                    elevation="0"
                                                    @click="is_dialog_color_unactive_open = true"
                                                >
                                                    <div class="px-2 py-1">
                                                        <p class="color-white-fix mb-0">
                                                            {{ $t('dialog_update_alert_component.color_alert_not_trigger') }}
                                                        </p>
                                                        <p class="color-white-fix mb-2">
                                                            {{ local_alert.configuration_appearance.color_untriggered }}
                                                        </p>
                                                    </div>
                                                </v-sheet>

                                                <v-dialog v-model="is_dialog_color_unactive_open" max-width="450px">
                                                    <v-card class="mx-auto" width="300px">
                                                        <v-card-actions>
                                                            <span class="font-weight-bold">
                                                                {{ $t('dialog_update_alert_component.selection_color_alert_not_trigger') }}
                                                            </span>
                                                        </v-card-actions>

                                                        <v-card-text class="pb-1">
                                                            <v-color-picker
                                                                v-model="color_untriggered"
                                                                class="mx-auto border"
                                                                mode="hexa"
                                                            />
                                                        </v-card-text>
                                                        <v-card-actions class="justify-end">
                                                            <v-btn
                                                                text
                                                                @click="
                                                                    color_untriggered =
                                                                        local_alert.configuration_appearance.color_untriggered
                                                                    is_dialog_color_unactive_open = false
                                                                "
                                                            >
                                                                <span class="font-color-light font-weight-bold">{{
                                                                    $t('global.cancel')
                                                                }}</span>
                                                            </v-btn>

                                                            <v-btn
                                                                class="btn-secondary-loader"
                                                                color="secondary"
                                                                text
                                                                @click="
                                                                    local_alert.configuration_appearance.color_untriggered =
                                                                        color_untriggered
                                                                    is_dialog_color_unactive_open = false
                                                                "
                                                            >
                                                                <span class="font-weight-bold secondary--text">{{
                                                                    $t('global.validate')
                                                                }}</span>
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>

                                                <icon-picker-component
                                                    :color_icon="local_alert.configuration_appearance.color_triggered"
                                                    :icon="local_alert.configuration_appearance.icon_triggered"
                                                    :label="$t('dialog_update_alert_component.icon_alert_trigger')"
                                                    type="active"
                                                    v-on:update:icon="handleUpdateIcon"
                                                />

                                                <v-sheet
                                                    :color="local_alert.configuration_appearance.color_triggered"
                                                    class="mb-4 mt-2 rounded rounded-b-0"
                                                    elevation="0"
                                                    @click="is_dialog_color_active_open = true"
                                                >
                                                    <div class="px-2 py-1">
                                                        <p class="color-white-fix mb-0">
                                                            {{ $t('dialog_update_alert_component.color_alert_trigger') }}
                                                        </p>
                                                        <p class="color-white-fix mb-2">
                                                            {{ local_alert.configuration_appearance.color_triggered }}
                                                        </p>
                                                    </div>
                                                </v-sheet>

                                                <v-dialog v-model="is_dialog_color_active_open" max-width="450px">
                                                    <v-card class="mx-auto" width="300px">
                                                        <v-card-actions>
                                                            <span class="font-weight-bold">
                                                                {{ $t('dialog_update_alert_component.selection_color_alert_trigger') }}
                                                            </span>
                                                        </v-card-actions>

                                                        <v-card-text class="pb-1">
                                                            <v-color-picker v-model="color_triggered" class="mx-auto border" mode="hexa" />
                                                        </v-card-text>

                                                        <v-card-actions class="justify-end">
                                                            <v-btn
                                                                text
                                                                @click="
                                                                    color_triggered = local_alert.configuration_appearance.color_triggered
                                                                    is_dialog_color_active_open = false
                                                                "
                                                            >
                                                                <span class="font-color-light font-weight-bold">{{
                                                                    $t('global.cancel')
                                                                }}</span>
                                                            </v-btn>

                                                            <v-btn
                                                                class="btn-secondary-loader"
                                                                color="secondary"
                                                                text
                                                                @click="
                                                                    local_alert.configuration_appearance.color_triggered = color_triggered
                                                                    is_dialog_color_active_open = false
                                                                "
                                                            >
                                                                <span class="font-weight-bold secondary--text">{{
                                                                    $t('global.validate')
                                                                }}</span>
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>

                                                <div class="d-flex gap-2">
                                                    <v-switch
                                                        v-if="local_alert.configuration[0].type === 'limit'"
                                                        v-model="local_alert.is_show_in_charts"
                                                        :label="$t('dialog_update_alert_component.display_threshold_on_curves')"
                                                        class="pl-1"
                                                        color="primary"
                                                        inset
                                                    >
                                                    </v-switch>

                                                    <v-tooltip top>
                                                        <span class="white--text">
                                                            {{
                                                                $t(
                                                                    `dialog_update_alert_component.threshold_indicator_will_displayed_on_curves`,
                                                                    [
                                                                        `${metric_of_configurations[0].label} ${
                                                                            metric_of_configurations[0].unit
                                                                                ? `(${metric_of_configurations[0].unit})`
                                                                                : ''
                                                                        }`,
                                                                    ]
                                                                )
                                                            }}
                                                        </span>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon small v-bind="attrs" v-on="on"> mdi-information </v-icon>
                                                        </template>
                                                    </v-tooltip>
                                                </div>
                                            </v-card-text>
                                        </v-expand-transition>
                                    </v-card>
                                </div>

                                <v-divider class="my-4" />

                                <!-------------
                                    CONTACT
                                -------------->
                                <p
                                    class="text-decoration-underline text-uppercase font-16 secondary--text font-weight-bold pointer"
                                    @click="is_display_advanced_option = !is_display_advanced_option"
                                >
                                    {{ $t('dialog_update_alert_component.advanced_settings') }}
                                </p>

                                <v-expand-transition>
                                    <div v-if="is_display_advanced_option">
                                        <span>{{ $t('dialog_update_alert_component.default_contact_preferences') }}</span>
                                        <v-row class="ma-0 mb-2 mt-2 min-height-40">
                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.badge') }}
                                                </p>
                                                <v-switch
                                                    v-model="local_alert.notification_default_badge"
                                                    class="mt-0"
                                                    hide-details
                                                    inset
                                                />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.notifications') }}
                                                </p>
                                                <v-switch v-model="local_alert.notification_default_push" class="mt-0" hide-details inset />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-2 mb-0">
                                                    {{ $t('dialog_update_alert_component.sms') }}
                                                </p>
                                                <v-switch v-model="local_alert.notification_default_sms" class="mt-0" hide-details inset />
                                            </div>

                                            <div>
                                                <p class="font-12 ml-1 mb-0">
                                                    {{ $t('dialog_update_alert_component.email') }}
                                                </p>
                                                <v-switch
                                                    v-model="local_alert.notification_default_email"
                                                    class="mt-0"
                                                    hide-details
                                                    inset
                                                />
                                            </div>
                                        </v-row>

                                        <v-radio-group v-model="local_alert.trigger_frequency_default" class="mt-0 mb-2" hide-details>
                                            <v-radio :value="-1">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.one_time') }}
                                                    </span>
                                                </template>
                                            </v-radio>

                                            <v-radio :value="24">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.each_day') }}
                                                    </span>
                                                </template>
                                            </v-radio>

                                            <v-radio :value="0">
                                                <template v-slot:label>
                                                    <span class="font-14">
                                                        {{ $t('dialog_update_alert_component.each_data') }}
                                                    </span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>

                                        <v-alert close-icon="mdi-close" dismissible icon="mdi-information-outline" text type="info">
                                            {{ $t('dialog_update_alert_component.frequency_common') }}
                                        </v-alert>
                                    </div>
                                </v-expand-transition>
                            </div>
                        </v-expand-transition>
                    </v-form>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="closeDialogEditAlert()">
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>

                    <v-btn
                        :disabled="!valid"
                        :loading="editing_alert"
                        class="btn-secondary-loader"
                        color="secondary"
                        text
                        @click="prepareEditAlert()"
                    >
                        <span :class="valid ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                            {{ $t('global.validate') }}
                        </span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import IconPickerComponent from '@/components/Alert/IconPickerComponent'
import AlertRepository from '../../repositories/AlertRepository'
import DeviceRepository from '@/repositories/DeviceRepository'
import { EmptyAlert } from '@/models/IAlert'

export default {
    name: 'DialogUpdateAlertComponent',
    components: { IconPickerComponent },
    data() {
        return {
            is_dialog_edit_alert_open: false,

            local_alert: EmptyAlert,
            is_new_alert: false,
            color_untriggered: '',
            color_triggered: '',
            metric_of_configurations: [],
            editing_alert: false,

            fetching_metrics: false,
            metrics: [],

            time_unites: [
                {
                    value: 'minute',
                    label: 'minutes',
                },
                {
                    value: 'hours',
                    label: 'heures',
                },
            ],

            trigger_frequency: [
                {
                    value: -1,
                    label: 'default',
                },
            ],

            is_dialog_color_active_open: false,
            is_dialog_color_unactive_open: false,
            alert_calculators: [
                { value: 'limit', text: this.$t('dialog_update_alert_component.limit') },
                { value: 'variation', text: this.$t('dialog_update_alert_component.variation') },
            ],

            display_alert_configuration_section: {
                configuration_0: true,
                option_display: false,
                option_contact: false,
            },
            is_display_advanced_option: false,
            rebuild: false,
        }
    },
    computed: {
        valid() {
            let valid_configuration = true
            this.local_alert.configuration.forEach((configuration) => {
                if (
                    configuration.type === null ||
                    configuration.operator === null ||
                    configuration.limit === null ||
                    configuration.limit === ''
                ) {
                    valid_configuration = false
                }

                if (
                    configuration.type === 'variation' &&
                    (configuration.variation_time === null ||
                        configuration.variation_time === '' ||
                        configuration.time_unites_selected === null)
                ) {
                    valid_configuration = false
                }
            })

            return (
                valid_configuration &&
                this.local_alert.label !== '' &&
                this.local_alert.configuration_appearance.icon_triggered !== null &&
                this.local_alert.configuration_appearance.color_triggered !== null &&
                this.local_alert.configuration_appearance.icon_untriggered !== null &&
                this.local_alert.configuration_appearance.color_untriggered !== null
            )
        },
    },
    methods: {
        resetUpdateAlertDialog() {
            this.local_alert = EmptyAlert
            this.is_display_advanced_option = false
            this.metric_of_configurations = []
            this.manageDisplayAlertConfigurationSection('configuration_0')
        },

        async openDialogEditAlert(alert, is_new_alert = false) {
            if (this.userHasAccess('BASE', this.role_edit)) {
                this.resetUpdateAlertDialog()
                this.local_alert = JSON.parse(JSON.stringify(alert))
                this.is_new_alert = is_new_alert
                this.is_dialog_edit_alert_open = true
                await this.getMetrics(alert.id_device)

                if (!is_new_alert) {
                    this.local_alert.configuration.forEach((configuration) => {
                        if (configuration.field === 'metric_asset') {
                            this.metric_of_configurations.push(this.metrics.find((metric) => metric.path === configuration.path))
                        } else {
                            this.metric_of_configurations.push(this.metrics.find((metric) => metric.id_metric === configuration.id_metric))
                        }
                    })
                } else {
                    this.local_alert.configuration_appearance.icon_triggered = 'alert-minus-outline'
                    this.local_alert.configuration_appearance.icon_untriggered = 'alert-minus-outline'
                }
            }
        },

        handleUpdateIcon(new_icon, type) {
            if (type === 'active') {
                this.local_alert.configuration_appearance.icon_triggered = new_icon
            } else {
                this.local_alert.configuration_appearance.icon_untriggered = new_icon
            }
        },

        closeDialogEditAlert() {
            this.is_dialog_edit_alert_open = false
        },

        async getMetrics(id_device) {
            this.fetching_metrics = true
            return DeviceRepository.getAllMetricByIdDevice(id_device)
                .then((success) => {
                    this.metrics = success.data.data.sort(function (x, y) {
                        return x.is_principal === y.is_principal ? 0 : x.is_principal ? -1 : 1
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.fetching_metrics = false
                })
        },

        manageDisplayAlertConfigurationSection(module) {
            this.rebuild = true
            Object.keys(this.display_alert_configuration_section).forEach((key) => {
                this.display_alert_configuration_section[key] = false
            })

            this.display_alert_configuration_section[module] = true
            this.rebuild = false
        },

        addConditionToAlert() {
            this.local_alert.configuration.push({
                path: null,
                id_metric: null,
                type: null,
                operator: null,
                limit: null,
                unit: null,
                variation_time: null,
                time_unites_selected: null,
            })

            this.display_alert_configuration_section['configuration_' + (this.local_alert.configuration.length - 1)] = false
            this.manageDisplayAlertConfigurationSection('configuration_' + (this.local_alert.configuration.length - 1))
        },

        deleteConditionToAlert(index) {
            this.local_alert.configuration.splice(index, 1)
            this.metric_of_configurations.splice(index, 1)
        },

        autoCompleteLabelAlert() {
            let label = ''

            this.local_alert.configuration.forEach((configuration, index) => {
                if (this.metric_of_configurations[index] && configuration.type && configuration.operator && configuration.limit) {
                    let and = index >= 1 ? `${this.$t('dialog_update_alert_component.and')} ` : ''

                    let operator = configuration.operator

                    let metric
                    if (this.metric_of_configurations[index].associate_to === 'metric_asset') {
                        metric = this.metrics.find((metric) => metric.path === this.metric_of_configurations[index].path)
                    } else {
                        metric = this.metrics.find((metric) => metric.id_metric === this.metric_of_configurations[index].id_metric)
                    }

                    label += `${and} ${metric.label ?? ''} ${operator ? operator : ''} ${configuration.limit ? configuration.limit : ''} ${
                        metric.unit ? metric.unit : ''
                    } `

                    if (configuration.type === 'variation') {
                        label += `${this.$t('dialog_update_alert_component.in')} ${
                            configuration.variation_time ? configuration.variation_time : ''
                        }  ${configuration.time_unites_selected ? configuration.time_unites_selected.label : ''}`
                    }
                }
            })
            this.local_alert.label = label
        },

        buildAlertConfiguration() {
            this.metric_of_configurations.forEach((metric, index) => {
                this.local_alert.configuration[index].field = metric.associate_to
                this.local_alert.configuration[index].unit = metric.unit
                if (metric.associate_to === 'metric_asset') {
                    this.local_alert.configuration[index].path = metric.path
                    this.local_alert.configuration[index].id_metric = null
                } else {
                    this.local_alert.configuration[index].id_metric = metric.id_metric
                    this.local_alert.configuration[index].path = null
                }
            })
        },

        prepareEditAlert() {
            this.editing_alert = true

            this.local_alert.configuration.forEach((configuration, index) => {
                if (configuration.type === 'variation' && configuration.time_unites_selected.value === 'hours') {
                    this.local_alert.configuration[index].variation_time *= 60
                }
            })

            this.buildAlertConfiguration()

            if (this.is_new_alert) {
                this.local_alert.id_device = this.$parent.asset.devices[0].id_device
                AlertRepository.addAlert(this.local_alert)
                    .then(() => {
                        this.closeDialogEditAlert()
                        this.$emit('refresh:alert')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.editing_alert = false
                    })
            } else {
                AlertRepository.updateAlert(this.local_alert.id_alert, this.local_alert)
                    .then(() => {
                        this.showSnackbar('success', this.$t('dialog_update_alert_component.updated'))
                        this.$emit('refresh:alert')
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.editing_alert = false
                        this.closeDialogEditAlert()
                    })
            }
        },
    },
    watch: {
        'local_alert.configuration': {
            handler() {
                this.autoCompleteLabelAlert()
            },
            deep: true,
        },
        metric_of_configurations: {
            handler() {
                this.autoCompleteLabelAlert()
            },
            deep: true,
        },
    },
}
</script>

<style>
.text-field-duo {
    max-width: 49% !important;
}
</style>
