import axios from '@/plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IAlert } from '@/models/IAlert'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IAlertEvent } from '@/models/IAlertEvent'

const resource = 'api/v1/alert'

export default {
    getAlertById(idAlert: number): Promise<AxiosResponse<IServerResponse<IAlert>>> {
        return axios.get(`${resource}/${idAlert}`)
    },

    getAlertEvents(idAlert: number): Promise<AxiosResponse<IServerResponse<IAlertEvent[]>>> {
        return axios.get(`${resource}/${idAlert}/events`)
    },

    addAlert(alert: IAlert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}`, alert)
    },

    updateAlert(idAlert: number, alert): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}`, alert)
    },

    deleteAlert(idAlert: number): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.delete(`${resource}/${idAlert}`)
    },

    subscribeUserToAlert(idAlert: number, user_alert_preference): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/${idAlert}/subscription`, user_alert_preference)
    },

    updateSubscriptionAlert(idAlert: number, user_alert_preference): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/subscription`, user_alert_preference)
    },

    acquittedAlert(idAlert: number): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/acquit`)
    },

    unAcquittedAlert(idAlert: number): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/${idAlert}/disacquit`)
    },
}
